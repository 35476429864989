<template>
  <section>
    <h2>Settings</h2>
    <b-card>
      <b-form-group>
        <label>Default show all orders</label>
        <b-form-checkbox
          id="checkbox-1"
          v-model="samePerson"
          name="checkbox-1"
          value="false"
          unchecked-value="true"
        >
          Contact person <strong>on-site</strong> is the one who orders
        </b-form-checkbox>
      </b-form-group>
    </b-card>
    <b-card>
      <b-form-group class=" ">
        <lable>Select language</lable>
        <b-form-select
          v-model="selected"
          :options="supportedLang"
        ></b-form-select>
      </b-form-group>
    </b-card>

    <b-button variant="success" class="mr-2">Save</b-button>
    <back-button class=""></back-button>
  </section>
</template>

<script>
import backButton from "@/components/Buttons/BackButton";

export default {
  name: "settings",
  components: {
    backButton
  },
  data() {
    return {
      samePerson: true,
      selected: "English",
      supportedLang: ["English"]
    };
  }
};
</script>

<style></style>
